define("discourse/plugins/discourse-group-directory/discourse/components/consultant-info", ["exports", "discourse/components/user-info"], function (_exports, _userInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _userInfo.default.extend({
    size: "huge"
  });
});