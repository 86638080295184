define("discourse/plugins/discourse-group-directory/discourse/components/group-directory-item", ["exports", "discourse-common/utils/decorators", "discourse/models/composer", "discourse/lib/url", "@ember/component", "discourse-common/lib/get-owner", "@ember/object/computed"], function (_exports, _decorators, _composer, _url, _component, _getOwner, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const minimizedMap = [210, 229, 256, 287];
  const nameUsernameDiff = function (user) {
    return user.name.replace(/[\-\_ \.]/g, "").toLowerCase() != user.username.replace(/[\-\_ \.]/g, "").toLowerCase();
  };
  const bioLength = function (user) {
    return user.bio_cooked.replace(/<a\b[^>]*>(.*?)<\/a>/i, "").length;
  };
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: 'tr',
    attributeBindings: ['style'],
    bioExpanded: (0, _computed.equal)('bioHeight', '100%'),
    collapsedBioHeight: null,
    style(hasHighlightBadge) {
      let style = '';
      if (hasHighlightBadge) {
        const color = this.siteSettings.rstudio_highlight_color;
        style += `background-color: ${color};`.htmlSafe();
      }
      return style;
    },
    didInsertElement() {
      this.setCollapsedBioHeight();
    },
    click() {
      _url.default.routeTo((0, _url.userPath)(this.user.username_lower));
    },
    setCollapsedBioHeight() {
      const user = this.user;
      let collapsedBioHeight;
      if (user.bio_cooked && bioLength(user) > 538) {
        let fields = 0;
        ['name', 'company', 'location'].forEach(attr => {
          if (user[attr]) {
            fields += 1;
          }
        });
        collapsedBioHeight = minimizedMap[fields];
        this.set('collapsedBioHeight', collapsedBioHeight);
      }
      this.set('bioHeight', `${collapsedBioHeight}px` || '100%');
    },
    bioHeightStyle(bioHeight) {
      return bioHeight || '100%';
    },
    displayBadges(consultantBadges) {
      return (consultantBadges || []).filter(b => this.userHasBadge(b));
    },
    userHasBadge(badge) {
      const user = this.user;
      return user.user_badges && user.user_badges.any(b => b.badge_id == badge.id);
    },
    hasHighlightBadge(user, consultantBadges) {
      const highlightBadge = consultantBadges.find(b => b.name == this.siteSettings.rstudio_highlight_badge);
      if (!user.user_badges || !highlightBadge) return false;
      return user.user_badges.any(b => b.badge_id == highlightBadge.id);
    },
    actions: {
      expandBio() {
        this.set('bioHeight', '100%');
      },
      collapseBio() {
        if (this.collapsedBioHeight) {
          this.set('bioHeight', `${this.collapsedBioHeight}px`);
        }
      },
      sendMessage() {
        if (this.currentUser) {
          (0, _getOwner.getOwner)(this).lookup('controller:composer').open({
            action: _composer.default.PRIVATE_MESSAGE,
            usernames: this.user.username,
            archetypeId: 'private_message',
            draftKey: 'new_private_message'
          });
        } else {
          this.application.send("showLogin");
        }
      }
    }
  }, [["method", "style", [(0, _decorators.default)('hasHighlightBadge')]], ["method", "bioHeightStyle", [(0, _decorators.default)('bioHeight')]], ["method", "displayBadges", [(0, _decorators.default)('consultantBadges')]], ["method", "hasHighlightBadge", [(0, _decorators.default)('user', 'consultantBadges')]]]));
});