define("discourse/plugins/discourse-group-directory/discourse/controllers/consultants-index", ["exports", "discourse/controllers/group-index", "discourse-common/utils/decorators", "discourse/models/user", "@ember/runloop", "@ember/object/computed", "@ember/object", "discourse/lib/debounce", "discourse-common/config/environment"], function (_exports, _groupIndex, _decorators, _user, _runloop, _computed, _object, _debounce, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const filterableFields = ['name', 'username', 'company', 'consult_language', 'bio_cooked', 'location'];
  var _default = _exports.default = _groupIndex.default.extend(dt7948.p({
    sortedMembers: (0, _computed.sort)('model.members', 'sortProperties'),
    isConsultantsPage: (0, _computed.equal)('model.name', 'consultants'),
    sortProperties() {
      return [this.siteSettings.rstudio_consultant_var_01_field + ':desc'];
    },
    memberParams(order, desc, filter) {
      return {
        order,
        desc,
        filter
      };
    },
    filterMembers: (0, _debounce.default)(function () {
      this.set("loading", true);
      const memberCache = this.get("memberCache");
      const filter = this.get("filter");
      if (memberCache) {
        let users = memberCache.filter(user => {
          return !filter || filterableFields.any(attr => {
            return user[attr] && user[attr].toLowerCase().includes(filter.toLowerCase());
          });
        });
        this.set("model.members", users);
      }
      this.set("loading", false);
    }, _environment.INPUT_DELAY),
    findConsultants() {
      if (!this.currentUser || this.currentUser && !this.currentUser.admin) {
        (0, _runloop.scheduleOnce)('afterRender', this, function () {
          $(`.user-content-wrapper`).addClass("non-admin");
        });
      }
      this.set("loading", true);
      const model = this.get("model");
      this.model.reloadMembers(this.memberParams, true).then(() => {
        ['members', 'owners'].forEach(k => {
          if (model[k] === null || model[k] === undefined) {
            model.set(k, 0);
          }
        });
        this.set("application.showFooter", model.members.length >= model.user_count - model.owners.length);
        this.set("loading", false);
        this.set("memberCache", model.members);
      });
    }
  }, [["method", "sortProperties", [_decorators.default]], ["method", "memberParams", [(0, _decorators.default)("order", "desc", "filter")]], ["field", "filterMembers", [(0, _decorators.observes)('filter')]]]));
});