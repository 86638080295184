define("discourse/plugins/discourse-group-directory/discourse/initializers/consultants-initializer", ["exports", "discourse/lib/plugin-api", "discourse/models/user-badge", "discourse/models/badge-grouping", "discourse/models/badge"], function (_exports, _pluginApi, _userBadge, _badgeGrouping, _badge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeConsultants(api) {
    api.modifyClass('route:group-index', {
      afterModel(model) {
        if (model && model.name == 'consultants') {
          return _badge.default.findAll().then(function (result) {
            model.set('consultantBadges', result.filter(b => b.badge_grouping.name == "Consultants"));
          });
        } else {
          return this._super(model);
        }
      },
      setupController(controller, model) {
        if (model.name == 'consultants') {
          this.controllerFor("group").set("showing", "members");
          const consultantsController = this.controllerFor('consultants-index');
          consultantsController.setProperties({
            model,
            filterInput: this._params.filter
          });
          consultantsController.findConsultants();
        } else {
          this._super(controller, model);
        }
      },
      renderTemplate(controller, model) {
        if (model && model.name == 'consultants') {
          this.render('consultants-index');
        } else {
          this._super(controller, model);
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "consultants-initializer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.group_directory_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.24", initializeConsultants);
      }
    }
  };
});