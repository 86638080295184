define("discourse/plugins/discourse-group-directory/discourse/templates/components/consultant-info", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-image">
    <div class="user-image-inner">
      <a href={{this.userPath}} data-user-card={{@user.username}}>{{avatar @user imageSize="huge"}}</a>
      {{#if @user.primary_group_name}}
        {{avatar-flair
          flairURL=@user.primary_group_flair_url
          flairBgColor=@user.primary_group_flair_bg_color
          flairColor=@user.primary_group_flair_color
          groupName=@user.primary_group_name}}
      {{/if}}
    </div>
  </div>
  
  <div class="user-detail">
    <div class="name-line">
      <span class="username"><a href={{this.userPath}} data-user-card={{@user.username}}>{{format-username @user.username}}</a></span>
      <span class="name">{{this.name}}</span>
      {{plugin-outlet name="after-user-name" connectorTagName="span" args=(hash user=user)}}
    </div>
    <div class="title">{{@user.title}}</div>
  </div>
  */
  {
    "id": "4lhiG41S",
    "block": "[[[10,0],[14,0,\"user-image\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"user-image-inner\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,[28,[35,0],[[30,1]],[[\"imageSize\"],[\"huge\"]]]],[13],[1,\"\\n\"],[41,[30,1,[\"primary_group_name\"]],[[[1,\"      \"],[1,[28,[35,2],null,[[\"flairURL\",\"flairBgColor\",\"flairColor\",\"groupName\"],[[30,1,[\"primary_group_flair_url\"]],[30,1,[\"primary_group_flair_bg_color\"]],[30,1,[\"primary_group_flair_color\"]],[30,1,[\"primary_group_name\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"user-detail\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"name-line\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"username\"],[12],[10,3],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,[28,[35,3],[[30,1,[\"username\"]]],null]],[13],[13],[1,\"\\n    \"],[10,1],[14,0,\"name\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"name\",\"connectorTagName\",\"args\"],[\"after-user-name\",\"span\",[28,[37,5],null,[[\"user\"],[[30,0,[\"user\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-group-directory/discourse/templates/components/consultant-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@user\"],false,[\"avatar\",\"if\",\"avatar-flair\",\"format-username\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-group-directory/discourse/templates/components/consultant-info.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});